import React, { useEffect, useState, forwardRef } from 'react';
import 'sweetalert2/dist/sweetalert2.min.css';

// reactstrap components
import {
  Table,
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  Row,
  Col,
  hr,
  Control,
  Label,
} from 'reactstrap';
// core components

const AddWallet = ({
  addToWalletModalVisibilty,
  setAddToWalletModalVisibilty,
  walletAmount,
  setWalletAmount,
  submitBalance,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={addToWalletModalVisibilty}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent ">
              <div className="">
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() =>
                    setAddToWalletModalVisibilty(!addToWalletModalVisibilty)
                  }
                >
                  <span>×</span>
                </button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" autoComplete="off">
                  <Row>
                    <Col>
                      <FormGroup className="mb-12">
                        <InputGroup className="input-group-alternative">
                          <Input
                            placeholder="Amount"
                            type="number"
                            value={walletAmount.money}
                            onChange={(e) => {
                              setWalletAmount({
                                ...walletAmount,
                                money: e.target.value,
                              });
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* email and password */}
                  <Row>
                    <Col>
                      <FormGroup className="mb-12 ">
                        <InputGroup className="input-group-alternative">
                          <Input
                            placeholder="BANK TRANSFARE"
                            type="text"
                            autoComplete="off"
                            value={walletAmount.transaction}
                            onChange={(e) => {
                              setWalletAmount({
                                ...walletAmount,
                                transaction: e.target.value,
                              });
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* role and team */}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={(e) => submitBalance()}
                    >
                      Add Ballance{' '}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </CardBody>
          </Card>
        </div>
      </Modal>
      <template id="my-template">
        <swal-title>Save changes to "Untitled 1" before closing?</swal-title>
        <swal-icon type="warning" color="red"></swal-icon>
        <swal-button type="confirm">Save As</swal-button>
        <swal-button type="cancel">Cancel</swal-button>
        <swal-button type="deny">Close without Saving</swal-button>
        <swal-param name="allowEscapeKey" value="false" />
        <swal-param name="customClass" value='{ "popup": "my-popup" }' />
      </template>
    </>
  );
};

export default AddWallet;
