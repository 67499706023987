/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from './views/Index.js';
import Profile from './views/examples/Profile.js';
import Login from './views/examples/Login.js';
import Messages from './views/examples/Messages.js';
import EditProfile from './views/examples/EditProfile';
import Company from './views/examples/Company/Companies';
import Users from './views/examples/user.js';
import Rembersment from './views/examples/Rembersment.js';
import InAdvance from './views/examples/Inadvance.js';
import SupplierRequests from './views/examples/supplierRequests.js';
import Suppliers from './views/examples/Suppliers.js';
import Transactions from './views/examples/transaction.js';
import Plans from './views/examples/Plans.js';
import CashoutSupplier from './views/examples/cashouts/supplier';
import CashoutUser from './views/examples/cashouts/user';
import Fawry from './views/examples/cashouts/FawryCashouts';

import LandingPageEn from './views/examples/LandingPageEn';
import LandingPageAr from './views/examples/LandingPageAr';
import Payrolls from './views/examples/payroll.js';

var routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
    api: false,
  },
  {
    path: '/user-cashouts',
    name: 'User Cashouts',
    icon: 'ni ni-tv-2 text-primary',
    component: CashoutUser,
    layout: '/admin',
    api: false,
  },
  {
    path: '/user-fawry-cashouts',
    name: 'User Fawry Cashouts',
    icon: 'ni ni-tv-2 text-primary',
    component: Fawry,
    layout: '/admin',
    api: false,
  },
  {
    path: '/supplier-cashouts',
    name: 'Supplier Cashouts',
    icon: 'ni ni-tv-2 text-primary',
    component: CashoutSupplier,
    layout: '/admin',
    api: false,
  },
  {
    path: '/payroll',
    name: 'Payrolls',
    icon: 'ni ni-tv-2 text-primary',
    component: Payrolls,
    layout: '/admin',
    api: false,
  },
  {
    path: '/companies',
    name: 'Companies',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Company,
    layout: '/admin',
    api: false,
  },

  {
    path: '/users',
    name: 'Users',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Users,
    layout: '/admin',
    api: false,
  },

  {
    path: '/rembersment',
    name: 'Rembersment Requests',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Rembersment,
    layout: '/admin',
    api: false,
  },

  {
    path: '/inadvance',
    name: 'In Advance Requests',
    icon: 'ni ni-bullet-list-67 text-red',
    component: InAdvance,
    layout: '/admin',
    api: false,
  },

  {
    path: '/supplier-request',
    name: 'Supplier Requests',
    icon: 'ni ni-bullet-list-67 text-red',
    component: SupplierRequests,
    layout: '/admin',
    api: false,
  },

  {
    path: '/suppliers',
    name: 'Suppliers',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Suppliers,
    layout: '/admin',
    api: false,
  },
  {
    path: '/Transactions',
    name: 'Transactions',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Transactions,
    layout: '/admin',
    api: false,
  },
  {
    path: '/plans',
    name: 'Plans',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Plans,
    layout: '/admin',
    api: false,
  },
  {
    path: '/tables',
    name: 'Messages',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Messages,
    layout: '/admin',
    api: false,
  },

  {
    path: '/user-profile',
    name: 'User Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: Profile,
    layout: '/admin',
    api: true,
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    api: true,
  },

  {
    path: '/edit-profile',
    name: 'Edit Profile',
    icon: 'ni ni-ruler-pencil text-info',
    component: EditProfile,
    layout: '/admin',
    api: true,
  },

  {
    path: '/landing-page-en',
    name: 'Landing Page En',
    icon: 'ni ni-bullet-list-67 text-red',
    component: LandingPageEn,
    layout: '/admin',
    api: false,
  },
  {
    path: '/landing-page-ar',
    name: 'Landing Page Ar',
    icon: 'ni ni-bullet-list-67 text-red',
    component: LandingPageAr,
    layout: '/admin',
    api: false,
  },
];
export default routes;
