import React, { useEffect, useState, forwardRef } from 'react';

// reactstrap components
import { Modal, Button } from 'antd';
import { Card, CardHeader, Container, Row } from 'reactstrap';
// core components

import MaterialTable from 'material-table';
import Header from '../../components/Headers/Header.js';
import {
  getTransactions,
  approveTransaction,
  declineTransaction,
} from '../../network/ApiAxios';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Eye from '@material-ui/icons/PanoramaFishEyeOutlined';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Eye: forwardRef((props, ref) => <Eye {...props} ref={ref} />),

  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const Transactions = () => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [item, setItem] = useState({});

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getTransactions().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setData(data.result);
      } else {
      }
    });
  };
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0"></CardHeader>
              <MaterialTable
                icons={tableIcons}
                columns={[
                  { title: 'Company', field: 'company.name' },

                  {
                    title: 'amount',
                    field: 'amount',
                  },
                  { title: 'Status', field: 'status' },
                  { title: 'Type', field: 'transaction' },
                  {
                    title: 'Image',
                    field: 'image',
                    render: (x) => (
                      <a href={x.image} target="_blank">
                        image link
                      </a>
                    ),
                  },

                  { title: 'Created At', field: 'createdAt' },
                ]}
                data={data}
                options={{
                  actionsColumnIndex: -1,
                  exportAllData: true,
                  exportButton: true,
                }}
                actions={[
                  (rowData) => ({
                    icon: tableIcons.Eye,
                    tooltip: 'View',

                    onClick: (event, rowData) => {
                      console.log(rowData);
                      setItem(rowData);
                      setIsModalVisible(true);
                    },
                  }),
                  (rowData) => ({
                    icon: tableIcons.Clear,
                    tooltip: 'Decline',
                    disabled: rowData.success != null,
                    onClick: (event, rowData) =>
                      declineTransaction(rowData._id).then((data) => {
                        getData();
                      }),
                  }),
                  (rowData) => ({
                    icon: tableIcons.Check,
                    tooltip: 'Approve',
                    disabled: rowData.success != null,

                    onClick: (event, rowData) =>
                      approveTransaction(rowData._id).then((data) => {
                        getData();
                      }),
                  }),
                ]}
              />
            </Card>
          </div>
        </Row>
        <Modal
          title="Basic Modal"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <img src={item.image}></img>
        </Modal>
      </Container>
    </>
  );
};

export default Transactions;
