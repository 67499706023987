import React,{useEffect, useState, useRef} from 'react';
import { addLandingPage,getLandingPage, addCompany} from '../../network/ApiAxios';
import Toast from 'react-bootstrap/Toast';
import { useForm } from "react-hook-form";
import ReactDOM from 'react-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert
} from 'reactstrap';
import Header from '../../components/Headers/Header.js';
// core components

const LandingPageEn = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [info, setInfo] = useState({});
  let [thirdSection, setThirdSection] = useState([]);
  let [fourthSection, setFourthSection] = useState({
    title:"",
    shortDescription:""
  });
  let [firstSection, setFirstSection] = useState({
    firstInfo:"",
    secondInfo:"",
    thirdInfo:""
  });
  let [reviews, setReviews] = useState([]);

  const [newThirdSection, setNewThirdSection] = useState({
    _id:"",
    title: "",
    shortDescription: "",
    Description: "",
    isActive: true
  });
  let [saveDisabled, setSaveDisabled] = useState(true);

  //react hook form
  const { register, handleSubmit,getValues , watch, formState: { errors } } = useForm({
    mode: "onBlur",
  });
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    getValues:getValues2
  } = useForm({
    mode: "onBlur",
  });
  const {
    register: registerFourthSection,
    formState: { errors: errorsFourthSection },
    handleSubmit: handleSubmitFourthSection,
    getValues:getValuesFourthSection
  } = useForm({
    mode: "onBlur",
  });
  const {
    register: registerThirdSection,
    formState: { errors: errorsThirdSection },
    handleSubmit: handleSubmitThirdSection,
    getValues:getValuesThirdSection
  } = useForm({
    mode: "onBlur",
  });
  const {
    register: registerFirstSection,
    formState: { errors: errorsFirstSection },
    handleSubmit: handleSubmitFirstSection,
    getValues:getValuesFirstSection
  } = useForm({
    mode: "onBlur",
  });
  const onSubmitThirdSection = data =>{
    }

    

    const onSubmitFourthSection = data =>{
      const newSection=[...reviews, data]
      setReviews(newSection);
      console.log("rev",reviews);
      }
 
  useEffect(() => {
    getLandingPage('Ar').then((response) => {
      const { data, status } = response;
      if (status == 200) {
       // console.log("dataaa",data);
       if(data.info==null)
       {
        setThirdSection([]);
        setFourthSection({});
        setFirstSection({});
        setReviews([]);
        console.log("infooooooooo",data.info);

       }
       else{
        firstSection.firstInfo=data.info.firstSection.firstInfo;
        firstSection.secondInfo=data.info.firstSection.secondInfo;
        firstSection.thirdInfo=data.info.firstSection.thirdInfo;
        setFirstSection(firstSection);
        setThirdSection(data.info.thirdSection);
        fourthSection.title=data.info.fourthSection.title;
        fourthSection.shortDescription=data.info.fourthSection.shortDescription;
        setFourthSection(fourthSection)
          setReviews(data.info.fourthSection.reviews)
        setInfo(data.info);
        console.log("infooooooooo",data.info);
       }
      } else {
      }
    });
  }, []);
  useEffect(()=>{

    console.log('new 3rd' ,thirdSection,reviews);
    checkEmptyField();
    
  },[thirdSection,reviews,firstSection,fourthSection])
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const [responseContent, setResponse] = useState("");


  const removeItem=(key)=>{
    const tasks = thirdSection.slice(); 
    tasks.splice(key, 1); 
    setThirdSection([...tasks]);
  }
  function checkProperties(obj) {
    let flag=false;
    for (var key in obj) {
        if (obj[key] !== null && obj[key] != ""){
          console.log("Faaalse",key,obj[key]);
            flag= false;
        }
            else{ 
              console.log("trueee",key,obj[key]);
              flag=true;
            break;}
        }
    
    return flag;
}
  const checkEmptyField=()=>{
    console.log("ggddffd",thirdSection,firstSection,fourthSection,reviews);
    if(thirdSection.length==0||firstSection=={}||fourthSection=={}||reviews.length==0)
    {
      setSaveDisabled(true);
    }
    else{
    let thirdSectionResult,reviewResult,fourthSectionResult,firstSectionResult;
    for (var element of reviews) {
       reviewResult = checkProperties(element);
       if(reviewResult==true){break;}
    } ;
    for (var element of thirdSection) {
      thirdSectionResult = checkProperties(element);
      if(thirdSectionResult==true){break;}

    } ;
      fourthSectionResult = checkProperties(fourthSection);
      firstSectionResult = checkProperties(firstSection);
     const result=reviewResult||thirdSectionResult||fourthSectionResult||firstSectionResult;
     console.log("bool",reviewResult,thirdSectionResult,fourthSectionResult);
    setSaveDisabled(result)
  }
  }

  const removeReviewItem=(key)=>{
      const tasks = reviews.slice(); 
      tasks.splice(key, 1);
      setReviews([...tasks]);
 
   }

 const updateItem=(index,value)=>{
  const tasks = thirdSection.slice(); 
  tasks[index]=value;
  setThirdSection(tasks);
  
  }
  const updateReviewsItem=(index,value)=>{
    const tasks = reviews.slice(); 
    tasks[index]=value;
    setReviews(tasks);
    }
//create third section
  const createThirdSection = (thirdSection) => {
    console.log("after adding...",thirdSection);
    return thirdSection.map((prop, key) => {
      return <div key={Math.floor(1000 + Math.random() * 9000)}
      style={{borderRadius:"10px",border:"0.5px",
        borderStyle:"solid",
        borderColor:"rgb(190 200 209)",
        padding:"10px",
        marginBottom:"30px"}}
        >
        <Row>
          <Col lg="6">
            <Row>
            <Col lg="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-Title"
                >
                  Title
                </label>
                <Input
                  className="form-control-alternative  form-control "
                  defaultValue={prop.title}
                  id="input-title"
                  placeholder="Title"
                  type="text"
                  required
                  //{...registerThirdSection("title", { required: true })}
                  onBlur ={(e) => {
                    e.preventDefault();
                    prop.title=e.target.value;
                    updateItem(key,prop);
                    checkEmptyField();
                    }}
                    />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-email"
                >
                  Short Description
                </label>
                <Input
                  className="form-control-alternative form-control"
                  id="input-email"
                  defaultValue={prop.shortDescription}
                  type="text"
                  placeholder="Short Description"
                  {...registerThirdSection("shortDescription", { required: true })}
                  onBlur={(e) => {
                    e.preventDefault();
                    prop.shortDescription=e.target.value;
                    updateItem(key,prop);
                    checkEmptyField();
                    }}
                    />
                {errorsThirdSection.shortDescription && <span style={{color:"red"}}>This field is required</span>}
              </FormGroup>
            </Col>
          </Row>
          </Col>
          <Col lg="6">
          <Row>
              <Col lg="12">
              <FormGroup>
              <label>Description</label>
              <Input
                  className="form-control-alternative form-control"
                  placeholder="A few words about this section ..."
                  rows="4"
                  defaultValue={prop.Description}
                  type="textarea"
                    {...registerThirdSection("Description", { required: true })}
                    onBlur={(e) => {
                      e.preventDefault();
                      prop.Description=e.target.value;
                      updateItem(key,prop);
                      checkEmptyField();
                      }}
                    />
                {errorsThirdSection.Description && <span style={{color:"red"}}>This field is required</span>}
              </FormGroup>
              </Col>
              <Col lg="12" className="text-right">
                    <Button
                      color="danger"
                      onClick={  () => {
                        removeItem(key)
                      }
                    }
                      size="sm"
                    >
                      Remove
                    </Button>
              </Col>
          </Row>
        </Col>
      </Row>
      </div>
      
    })
    };
    //create fourth section
    const createFourthSection = (reviews) => {
      return reviews.map((prop, key) => {
        return <div key={Math.floor(1000 + Math.random() * 9000)} 
        style={{borderRadius:"10px",border:"0.5px",
        borderStyle:"solid",
        borderColor:"rgb(190 200 209)",
        padding:"10px",
        marginBottom:"10px"}}>
          <Row>
            <Col lg="6">
              <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-Title"
                  >
                    Name
                  </label>
                  <Input
                    className="form-control-alternative form-control"
                    id="input-name"
                    placeholder="Name"
                    type="text"
                    defaultValue={prop.name}
                    onBlur ={(e) => {
                      e.preventDefault();
                      prop.name=e.target.value;
                      updateReviewsItem(key,prop);
                      checkEmptyField();
                      }}
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-email"
                  >
                    Email
                  </label>
                  <Input
                    className="form-control-alternative form-control"
                    id="input-email"
                    type="text"
                    placeholder="Email"
                    defaultValue={prop.email}
                    onBlur={(e) => {
                      e.preventDefault();
                      prop.email=e.target.value;
                      updateReviewsItem(key,prop);
                      checkEmptyField();
                      }}
                  />
                </FormGroup>
              </Col>
            </Row>
            </Col>
            <Col lg="6">
            <Row>
                <Col lg="12">
                <FormGroup>
                <label>Text</label>
                <Input
                     className="form-control-alternative form-control"
                     placeholder="A few words about this section ..."
                     rows="4"
                     type="textarea"
                     defaultValue={prop.text}
                    onChange={(e)=>{
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                      prop.text=e.target.value;
                      updateReviewsItem(key,prop);
                      checkEmptyField();
                      }}
                />
                </FormGroup>
                </Col>
                <Col lg="12" className="text-right">
                      <Button
                        color="danger"
                        onClick={  () => {
                       removeReviewItem(key)
                        }
                      }
                        size="sm"
                      >
                        Remove
                      </Button>
                </Col>
            </Row>
          </Col>
        </Row>
        </div>        
      })
      };

      const fireAlert=()=>{
        alert("saved successfully!");
      }
    const  resetThirdSectionForm = () => { 
        document.getElementById("ThirdSectionForm").reset();
      }
      const  resetReviewSectionForm = () => { 
        document.getElementById("ReviewSectionForm").reset();
      }
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Sections Info</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      disabled={saveDisabled}
                     // disabled={!submitDisabled}
                      onClick={() => {
                        console.log("opaaaaa",info);
                        info.firstSection=firstSection;
                        info.thirdSection=thirdSection;
                        info.fourthSection=fourthSection;
                        info.fourthSection.reviews=reviews;
                        info.lang="Ar";
                        info.thirdSection.forEach(function(v){ delete v._id });
                        info.fourthSection.reviews.forEach(function(v){ delete v._id });
                        delete info._id  ;  
                        delete info.__v  ;                    
                        addLandingPage(info,'Ar').then((response) => {
                          console.log(response.data);
                          const { data, status } = response;
                          if (status == 200) {
                            setResponse("Landing Page Info saved successfully");
                          } else { 
                          }
                        });
                        toggleShow
                        fireAlert();
                      }}
                      size="sm"
                    >
                      Save
                    </Button>
                  </Col>
                  <Col className='mt-3' xl="12">
          <Toast className="d-inline-block m-1" show={show} onClose={toggleShow} style={{backgroundColor: "#117def",padding: "5px",borderRadius:"5px",color:"white"}} >
          <Toast.Header>
          </Toast.Header>
          <Toast.Body bg="primary">{responseContent}</Toast.Body>
        </Toast>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              <h3 className="heading-small text-muted mb-4" style={{fontSize:"25px"}} >
                    First Section
                  </h3>
                  {/*first section************************************************************************/}
                <form key={5}>
                <Row>
                <Col lg="4" className="">
                <FormGroup className="mb-3 ">
                        <label
                        className="form-control-label"
                        htmlFor="input-Title"
                        >
                        Demo info (no.1)
                        </label>
                      <input
                        className="form-control-alternative form-control"
                        type="text"
                        //placeholder="Title"
                        defaultValue={firstSection.firstInfo}
                        {...registerFirstSection("firstInfo", { required: true })}
                        onBlur={e=>{
                          firstSection.firstInfo=e.target.value;
                          setFirstSection(firstSection)
                          checkEmptyField();
                        }}
                      />
                  {errorsFirstSection.firstInfo && <span style={{color:"red"}}>This field is required</span>}
                </FormGroup>
                </Col>
                <Col lg="4" className="">
                <FormGroup className="mb-3">
                <label
                        className="form-control-label"
                        htmlFor="input-Title"
                        >
                        Demo info (no.2)
                        </label>
                      <input
                       className="form-control-alternative form-control"
                        type="text"
                        //placeholder="Short Description"
                        defaultValue={firstSection.secondInfo}
                        {...registerFirstSection("secondInfo", { required: true })}
                        onBlur={e=>{
                          firstSection.secondInfo=e.target.value;
                          setFirstSection(firstSection)
                          checkEmptyField();
                        }}
                      />
                  {errorsFirstSection.secondInfo && <span style={{color:"red"}}>This field is required</span>}
                </FormGroup>
                </Col>
                <Col lg="4" className="">
                <FormGroup className="mb-3">
                <label
                        className="form-control-label"
                        htmlFor="input-Title"
                        >
                        Demo info (no.3)
                        </label>
                      <input
                       className="form-control-alternative form-control"
                        type="text"
                        //placeholder="Short Description"
                        defaultValue={firstSection.thirdInfo}
                        {...registerFirstSection("thirdInfo", { required: true })}
                        onBlur={e=>{
                          firstSection.thirdInfo=e.target.value;
                          setFirstSection(firstSection)
                          checkEmptyField();
                        }}
                      />
                  {errorsFirstSection.thirdInfo && <span style={{color:"red"}}>This field is required</span>}
                </FormGroup>
                </Col>
                
              </Row>
              </form>
              <hr className="my-4" />
                {/*third section************************************************************************/}
                <form key={4} >
                  <h3 className="heading-small text-muted mb-4" style={{fontSize:"25px"}}>
                    Third Section
                  </h3>
                  {createThirdSection(thirdSection)}
                  </form>
                  <form id="ThirdSectionForm" key={1} onSubmit={handleSubmit(onSubmitThirdSection)}>
                  <div>
                    <Row>
                    <Col lg="6">
                    <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="input-Title"
                        >
                        Title
                        </label>
                        <input
                        className="form-control-alternative form-control"
                        id="input-tilte"
                        placeholder="Title"
                        type="text"
                        {...register("title", { required: true })}
                        />
                    {errors.title && <span style={{color:"red"}}>This field is required</span>}
                    </FormGroup>
                    </Col>
                    <Col lg="6">
                    <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="input-short-description"
                        >
                        Short Description
                        </label>
                        <input
                        className="form-control-alternative form-control"
                        id="input-short-description"
                        type="text"
                        placeholder="Short Description"
                        {...register("shortDescription", { required: true })}
                        />
                       {errors.shortDescription && <span style={{color:"red"}}>This field is required</span>}
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                    <FormGroup>
                    <label>Description</label>
                    <textarea
                        className="form-control-alternative form-control"
                        placeholder="A few words about this section ..."
                        rows="4"
                        type="textarea"
                        {...register("Description", { required: true })}
                    />
                    {errors.Description && <span style={{color:"red"}}>This field is required</span>}
                    </FormGroup>
                    </Col>
                </Row>
                <Col className="text-right">

                    <input type="submit" className="btn btn-primary"
                    value="Add"
                    onClick={(e) => {
                     if(checkProperties(getValues()))
                     {
                        console.log("contain nuuuuuuuuuuuuuuul");
                     }
                     else{
                      const newSection=[...thirdSection, getValues()]
                      setThirdSection(newSection);
                      console.log("third",thirdSection);
                     }
                      const values = getValues();
                      console.log("register",values);
                      resetThirdSectionForm();

                    }
                  }
                    />
                  </Col>
                  </div>
                </form>
                <hr className="my-80" />
                {/*fourth section************************************************************************/}
                <h3 className="heading-small text-muted mb-4" style={{fontSize:"25px"}} >
                    Fourth Section
                  </h3>
                <form key={3} onSubmit={handleSubmitFourthSection()}>
                <Row>
                <Col>
                <FormGroup className="mb-3 ">
                        <label
                        className="form-control-label"
                        htmlFor="input-Title"
                        >
                        Title 
                        </label>
                      <input
                        className="form-control-alternative form-control"
                        id="input-title-Section4"
                        type="text"
                        placeholder="Title"
                        defaultValue={fourthSection.title}
                        {...registerFourthSection("title", { required: true })}
                        onBlur={e=>{
                          fourthSection.title=e.target.value;
                          setFourthSection(fourthSection)
                          checkEmptyField();
                        }}
                      />
                  {errorsFourthSection.title && <span style={{color:"red"}}>This field is required</span>}
                </FormGroup>
                </Col>
                <Col>
                <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="input-Title"
                        >
                        Short Description
                        </label>
                      <input
                       className="form-control-alternative form-control"
                        id="input-shortDescreption-Section4"
                        type="text"
                        placeholder="Short Description"
                        defaultValue={fourthSection.shortDescription}
                        {...registerFourthSection("shortDescription", { required: true })}
                        onBlur={e=>{
                          fourthSection.shortDescription=e.target.value;
                          setFourthSection(fourthSection)
                          checkEmptyField();
                        }}
                      />
                  {errorsFourthSection.shortDescription && <span style={{color:"red"}}>This field is required</span>}
                </FormGroup>
                </Col>
              </Row>
              </form>
              <hr className="my-4" />
              <h3 className="heading-small text-muted mb-4" style={{fontSize:"15px"}} >
                    Reviews
                  </h3>
              <Form >
                {createFourthSection(reviews)}
              </Form>
               <form id='ReviewSectionForm' key={2} onSubmit={handleSubmit2(onSubmitFourthSection)} style={{marginTop:"40px"}}>
                  <div>
                    <Row>
                    <Col lg="6">
                    <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="input-Title"
                        >
                        Name
                        </label>
                        <input
                        className="form-control-alternative form-control"
                        id="input-name"
                        placeholder="Name"
                        type="text"
                        {...register2("name", { required: true })}
                        />
                    {errors2.name && <span style={{color:"red"}}>This field is required</span>}
                    </FormGroup>
                    </Col>
                    <Col lg="6">
                    <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="input-short-email"
                        >
                        Email
                        </label>
                        <input
                        className="form-control-alternative form-control"
                        id="input-email"
                        type="text"
                        placeholder="Email"
                        {...register2("email", { required: true })}
                        />
                       {errors2.email && <span style={{color:"red"}}>This field is required</span>}
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                    <FormGroup>
                    <label>Text</label>
                    <textarea
                        className="form-control-alternative form-control"
                        placeholder="A few words about this section ..."
                        rows="4"
                        type="textarea"
                        {...register2("text", { required: true })}
                    />
                    {errors2.text && <span style={{color:"red"}}>This field is required</span>}
                    </FormGroup>
                    </Col>
                </Row>
                <Col className="text-right">

                    <input type="submit" className="btn btn-primary"
                    value="Add"
                    onClick={() => {
                      checkEmptyField();
                      resetReviewSectionForm();
                    }
                  }
                    />
                  </Col>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LandingPageEn;
