import React, { useEffect, useState } from 'react';

// reactstrap components
import { Card, CardHeader, Table, Container, Row } from 'reactstrap';
// core components
import Header from '../../components/Headers/Header.js';
import { getAll } from '../../network/ApiAxios';
const Messages = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getAll().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setData(data.msgs);
      } else {
      }
    });
  }, []);
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Card tables</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Company</th>
                    <th scope="col">Team</th>
                    <th scope="col">Role</th>

                    <th scope="col">Message</th>

                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {data.map((element) => (
                    <tr>
                      <td>{element.name}</td>
                      <td>{element.email}</td>
                      <td>{element.phone}</td>
                      <td>{element.companyName}</td>
                      <td>{element.team}</td>
                      <td>{element.role}</td>
                      <td style={{ whiteSpace: 'normal' }}>
                        {element.message}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};

export default Messages;
