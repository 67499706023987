import React, { forwardRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { CsvBuilder } from 'filefy';
import ReactDatetime from 'react-datetime';
import {
  InputGroupText,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Eye from '@material-ui/icons/PanoramaFishEyeOutlined';
import { getCompanies } from '../../../network/ApiAxios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Eye: forwardRef((props, ref) => <Eye {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export default function CashoutTable({
  data,
  declineTransaction,
  approveTransaction,
  getData,
  status,
  setData,
  type,
}) {
  const [startDate, setStartDate] = useState(new Date().getDate());
  const [endDate, setEndDate] = useState(new Date().getDate());
  const [companies, setCompanies] = useState([]);
  const [origin, setOrigin] = useState([...data]);

  const [company, setCompany] = useState('');

  const columns = [
    { title: 'TransactionID', field: 'TransactionID' },

    {
      title: 'CreditorName',
      field: 'CreditorName',
    },

    {
      title: 'CreditorAccountNumber',
      field: 'CreditorAccountNumber',
    },

    { title: 'CreditorBank', field: 'CreditorBank' },
    {
      title: 'Creditor Bank Branch',
      field: 'CreditorBankBranch',
    },

    { title: 'TransactionAmount', field: 'TransactionAmount' },
    {
      title: 'TransactionPurpose',
      field: 'TransactionAmount',
      render: () => <h5>CASH</h5>,
    },
    {
      title: 'Comments',
      field: 'TransactionID',
      render: (rowData) => (
        <h5>
          {status} cashout for Transaction {rowData.TransactionID}
        </h5>
      ),
    },

    { title: 'ReceiverEmail', field: 'ReceiverEmail' },

    { title: 'SMSMobileNumber', field: 'SMSMobileNumber' },
  ];
  useEffect(() => {
    console.log(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    getCompanies().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        let companies_ = [];
        data.result.forEach((e) => {
          companies_.push(e.name);
        });
        setCompanies(companies_);
      } else {
      }
    });
  }, []);
  useEffect(() => {
    console.log(company);
    let newCashouts = [];
    if (company == 'All') {
      setData(origin);
    } else {
      origin.forEach((e) => {
        if (e.companyName == company) {
          newCashouts.push(e);
        }
      });
      setData(newCashouts);
    }
  }, [company]);
  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: 'Date Picker Here',
                }}
                timeFormat={false}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate._d + '' === currentDate._d + ''
                  ) {
                    classes += ' start-date';
                  } else if (
                    startDate &&
                    endDate &&
                    new Date(startDate._d + '') <
                      new Date(currentDate._d + '') &&
                    new Date(endDate._d + '') > new Date(currentDate._d + '')
                  ) {
                    classes += ' middle-date';
                  } else if (
                    endDate &&
                    endDate._d + '' === currentDate._d + ''
                  ) {
                    classes += ' end-date';
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={(e) => setStartDate(e)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: 'Date Picker Here',
                }}
                timeFormat={false}
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  if (
                    startDate &&
                    endDate &&
                    startDate._d + '' === currentDate._d + ''
                  ) {
                    classes += ' start-date';
                  } else if (
                    startDate &&
                    endDate &&
                    new Date(startDate._d + '') <
                      new Date(currentDate._d + '') &&
                    new Date(endDate._d + '') > new Date(currentDate._d + '')
                  ) {
                    classes += ' middle-date';
                  } else if (
                    endDate &&
                    endDate._d + '' === currentDate._d + ''
                  ) {
                    classes += ' end-date';
                  }
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
                onChange={(e) => setEndDate(e)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>{' '}
      <Row>
        <Col>
          <FormGroup>
            <Input
              id="exampleFormControlInput1"
              placeholder="name@example.com"
              type="select"
              onChange={(e) => {
                console.log(e.target.value);
                setCompany(e.target.value);
              }}
            >
              <option selected>All</option>
              {companies.map((c) => (
                <option value={c}>{c}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <MaterialTable
        icons={tableIcons}
        title=""
        columns={
          type == 'fawry'
            ? [
                { title: 'Transaction ID', field: 'TransactionID' },

                {
                  title: 'Name',
                  field: 'CreditorName',
                },
                { title: 'Phone', field: 'SMSMobileNumber' },
                {
                  title: 'Amount',
                  field:
                    'TransactionAmount?.$numberDecimal || TransactionAmount',
                  render: (data) =>
                    data.TransactionAmount.$numberDecimal
                      ? data.TransactionAmount.$numberDecimal
                      : data.TransactionAmount,
                },
              ]
            : [
                { title: 'TransactionID', field: 'TransactionID' },
                {
                  title: 'cashoutDate',
                  field: 'createdAt',
                  export: false,
                },

                {
                  title: 'CreditorName',
                  field: 'CreditorName',
                },
                {
                  title: 'Company',
                  field: 'companyName',
                  export: false,
                  width: 'fit-content',
                },
                {
                  title: 'CreditorAccountNumber',
                  field: 'CreditorAccountNumber',
                },

                { title: 'CreditorBank', field: 'CreditorBank' },
                {
                  title: 'Creditor Bank Branch',
                  field: 'CreditorBankBranch',
                },

                {
                  title: 'TransactionAmount',
                  field:
                    'TransactionAmount?.$numberDecimal || TransactionAmount',
                  render: (data) =>
                    data.TransactionAmount.$numberDecimal
                      ? data.TransactionAmount.$numberDecimal
                      : data.TransactionAmount,
                },
                {
                  title: 'TransactionPurpose',
                  field: 'TransactionAmount',
                  render: () => <h5>CASH</h5>,
                },
                {
                  title: 'Comments',
                  field: 'TransactionID',
                  render: (rowData) => (
                    <h5>
                      {status} cashout for Transaction {rowData.TransactionID}
                    </h5>
                  ),
                },

                { title: 'ReceiverEmail', field: 'ReceiverEmail' },
                { title: 'SMSMobileNumber', field: 'SMSMobileNumber' },
              ]
        }
        data={data}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          selection: true,
          filtering: true,
          exportAllData: true,
          exportCsv: () => {
            let modedData = data.map((e) => ({
              ...e,
              createdAt: new Date(e.createdAt).toLocaleString(),
            }));
            console.log(modedData);
            const fileType =
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, {
              bookType: 'xlsx',
              type: 'array',
            });
            const data_ = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data_, 'cashouts' + fileExtension);
          },
        }}
        onSelectionChange={(rows) => console.log(rows)}
        actions={
          status == 'PENDING'
            ? [
                {
                  position: 'toolbarOnSelect',
                  icon: tableIcons.Export,
                  tooltip: 'Export the selected rows!',
                  onClick: (e, rowData) => {
                    const fileName = 'TestDate_Table';
                    const builder = new CsvBuilder(fileName + '.csv');
                    builder
                      .setColumns(columns.map((columnDef) => columnDef.title))
                      .addRows(
                        rowData.map((rowData) =>
                          columns.map((columnDef) => rowData[columnDef.field])
                        )
                      )
                      .exportFile();
                  },
                },
                (rowData) => ({
                  position: 'toolbarOnSelect',
                  icon: tableIcons.Clear,
                  tooltip: 'Decline',
                  disabled: rowData.success != null,
                  onClick: (event, rowData) => {
                    console.log(event, rowData);
                    let reqs = [];
                    for (const iterator of rowData) {
                      reqs.push({
                        id: iterator.TransactionID,
                        status: 'CANCELLED',
                      });
                    }
                    declineTransaction(reqs);
                  },
                }),
                (rowData) => ({
                  position: 'toolbarOnSelect',

                  icon: tableIcons.Check,
                  tooltip: 'Approve',
                  disabled: rowData.success != null,

                  onClick: (event, rowData) => {
                    console.log(event, rowData);
                    let reqs = [];
                    for (const iterator of rowData) {
                      reqs.push({
                        id: iterator.TransactionID,
                        status: 'PAID',
                      });
                    }
                    approveTransaction(reqs);
                  },
                }),
              ]
            : [
                {
                  position: 'toolbarOnSelect',
                  icon: tableIcons.Export,
                  tooltip: 'Export the selected rows!',
                  onClick: (e, rowData) => {
                    const fileName = 'TestDate_Table';
                    const builder = new CsvBuilder(fileName + '.csv');
                    builder
                      .setColumns(columns.map((columnDef) => columnDef.title))
                      .addRows(
                        rowData.map((rowData) =>
                          columns.map((columnDef) => rowData[columnDef.field])
                        )
                      )
                      .exportFile();
                  },
                },
              ]
        }
      />
    </>
  );
}
