import axios from 'axios';
import config from '../config';

// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? token : '';
  config.headers.ContentType = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
});

export const getAll = async () => await instance.get('msg/');

export const getCompanies = async () => await instance.get('company/');

export const getHeaderDsate = async () => await instance.get('state/num');

export const login = async (email, password) =>
  await instance.post('auth/login', { email, password });

export const logout = async (token) =>
  await instance.post('users/logout', { token });

export const getUsers = async () => await instance.get('user/');
export const deleteUser = async (id) => await instance.delete(`user/${id}`);

export const getRembersment = async () => await instance.get('rembersment/');

export const getInavance = async () => await instance.get('inadvance/');

export const getSupplierRequests = async () => await instance.get('supplier/');
export const getSuppliers = async () =>
  await instance.get('supplier/suppliers');

export const getTransactions = async () => await instance.get('transactions/');

export const getPayrolls = async () =>
  await instance.get('payroll/status?status=ALL');

export const editPayroll = async ({ payroll, status }) =>
  await instance.put('payroll/', { payroll, status });

export const approveTransaction = async (id) =>
  await instance.post('transactions/approve', { id });
export const declineTransaction = async (id) =>
  await instance.post('transactions/decline', { id });

export const getPlans = async () => await instance.get('plan/');
export const addPlan = async (data) => {
  data.utilities = data.utilities.split(/\n/);
  await instance.post('plan/', { ...data });
};

export const addCompany = async (data) => {
  console.log(data);
  // data.utilities = data.utilities.split(/\n/);
  const response = await instance.post('company/', data);
  return response;
};

export const addBalance = async (data) => {
  console.log(data);
  // data.utilities = data.utilities.split(/\n/);
  const response = await instance.post(
    `company/addBalance/${data.selectedCompanyId}`,
    data
  );
  return response;
};
export const deleteCompany = async (id) =>
  await instance.delete(`company/${id}`);

export const getCountries = async () => await instance.get('plan/country');

export const getLandingPage = async (lang) =>
  await instance.get('landingPageEn/lang', { params: { lang: lang } });
export const addLandingPage = async (data, lang) => {
  console.log(data);
  // data.utilities = data.utilities.split(/\n/);
  const response = await instance.put('landingPageEn/', data, {
    params: { lang: lang },
  });
  return response;
};

export const getCashouts = async (
  status = 'PENDING',
  type = 'EwalltetAndBank'
) =>
  await instance.get(
    `users-cashout/status/?cashoutType=${type}&status=${status}`
  );
export const updateCashoutStatus = async (cashouts) =>
  await instance.put(`users-cashout/`, { cashouts });
//
export const getSupplierCashouts = async (status = 'PENDING') =>
  await instance.get(`supplier-cashout/status/?status=${status}`);
export const updateSupplierCashoutStatus = async (cashouts) =>
  await instance.put(`supplier-cashout/`, { cashouts });
