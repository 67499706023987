import React, { useEffect, useState, forwardRef } from 'react';
import 'sweetalert2/dist/sweetalert2.min.css';

// reactstrap components
import { Card, CardHeader, CardBody, Modal, Table } from 'reactstrap';
// core components

const WalletHistory = ({
  walletModalVisibilty,
  setWalletModalVisibilty,
  walletLogs,
}) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={walletModalVisibilty}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent ">
              <div className="">
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() => setWalletModalVisibilty(!walletModalVisibilty)}
                >
                  <span>×</span>
                </button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Table>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {walletLogs.map((w) => (
                    <tr>
                      <td>{w.wallet}</td>
                      <td>{new Date(w.createdAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </Modal>
      <template id="my-template">
        <swal-title>Save changes to "Untitled 1" before closing?</swal-title>
        <swal-icon type="warning" color="red"></swal-icon>
        <swal-button type="confirm">Save As</swal-button>
        <swal-button type="cancel">Cancel</swal-button>
        <swal-button type="deny">Close without Saving</swal-button>
        <swal-param name="allowEscapeKey" value="false" />
        <swal-param name="customClass" value='{ "popup": "my-popup" }' />
      </template>
    </>
  );
};

export default WalletHistory;
