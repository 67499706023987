import React, { useEffect, useState, forwardRef } from 'react';

// reactstrap components
import {
  Table,
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  Row,
  Label,
} from 'reactstrap';
// core components

import MaterialTable from 'material-table';
import Header from '../../components/Headers/Header.js';
import { getPlans, addPlan, getCountries } from '../../network/ApiAxios';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Toast from 'reactstrap/lib/Toast';
import TextArea from 'antd/lib/input/TextArea';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Plans = () => {
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);

  const [plan, setPlan] = useState({
    name: '',
    price: '',
    discription: '',
    duration: '',
    utilities: '',
    isBest: false,
    country: '',
  });
  const [addModalVisibilty, setAddModalVisibilty] = useState(false);

  useEffect(() => {
    getCountries().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setCountries(data.country);
      } else {
      }
    });
    getPlans().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setData(data.plans);
      } else {
      }
    });
  }, [setAddModalVisibilty]);
  useEffect(() => {
    getPlans().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setData(data.plans);
      } else {
      }
    });
  }, []);
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0"></CardHeader>
              <MaterialTable
                icons={tableIcons}
                columns={[
                  { title: 'Name', field: 'name' },
                  { title: 'Price Per Month', field: 'price' },
                  { title: 'Discription', field: 'discription' },
                  { title: 'Country', field: 'country.country' },
                  { title: 'is best', field: 'isBest' },

                  { title: 'Created At', field: 'createdAt' },
                ]}
                data={data}
                actions={[
                  (rowData) => ({
                    icon: tableIcons.Edit,
                    tooltip: 'Edit',

                    onClick: (event, rowData) => {
                      console.log(rowData);
                    },
                  }),
                  (rowData) => ({
                    icon: tableIcons.Clear,
                    tooltip: 'Decline',
                    disabled: rowData.success != null,
                    // onClick: (event, rowData) =>
                    //   declineTransaction(rowData._id).then((data) => {
                    //     getData();
                    //   }),
                  }),
                  {
                    icon: tableIcons.Add,
                    tooltip: 'new plane',
                    isFreeAction: true,
                    onClick: () => setAddModalVisibilty(true),
                  },
                  (rowData) => ({
                    icon: tableIcons.Check,
                    tooltip: 'Approve',
                    disabled: rowData.success != null,

                    // onClick: (event, rowData) =>
                    //   approveTransaction(rowData._id).then((data) => {
                    //     getData();
                    //   }),
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                  exportAllData: true,
                  exportButton: true,
                }}
              />
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>

      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={addModalVisibilty}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent ">
              <div className="">
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() => setAddModalVisibilty(!addModalVisibilty)}
                >
                  <span>×</span>
                </button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Name"
                      type="text"
                      value={plan.name}
                      onChange={(e) => {
                        e.preventDefault();
                        console.log(e.target.value);
                        setPlan({ ...plan, name: e.target.value });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Price"
                      type="text"
                      value={plan.price}
                      onChange={(e) =>
                        setPlan({ ...plan, price: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Discription"
                      type="text"
                      value={plan.discription}
                      onChange={(e) =>
                        setPlan({ ...plan, discription: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Duration (Months)"
                      type="number"
                      value={plan.duration}
                      onChange={(e) =>
                        setPlan({ ...plan, duration: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Utilities"
                      type="textarea"
                      value={plan.utilities}
                      onChange={(e) => {
                        let lines = e.target.value;
                        setPlan({ ...plan, utilities: lines });
                      }}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Duration (Months)"
                      type="select"
                      value={plan.country}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setPlan({ ...plan, country: e.target.value });
                      }}
                    >
                      {countries.map((c) => (
                        <option value={c._id}>{c.country}</option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Duration (Months)"
                      type="checkbox"
                      id="exampleCheck"
                      value={plan.isBest}
                      onChange={(e) => {
                        setPlan({ ...plan, isBest: !plan.isBest });
                      }}
                    />
                    <Label for="exampleCheck" check>
                      is best option?{' '}
                    </Label>
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => {
                      addPlan(plan);
                      setAddModalVisibilty(false);
                      setPlan({
                        name: '',
                        price: '',
                        discription: '',
                        duration: '',
                        utilities: '',
                        isBest: false,
                        country: '',
                      });

                      getPlans().then((response) => {
                        const { data, status } = response;
                        if (status == 200) {
                          setData(data.plans);
                        } else {
                        }
                      });
                    }}
                  >
                    Add Plan{' '}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default Plans;
