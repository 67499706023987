import React, { useEffect, useState, forwardRef } from 'react';

// reactstrap components
import { Card, CardHeader, Table, Container, Row } from 'reactstrap';
// core components

import MaterialTable from 'material-table';
import Header from '../../components/Headers/Header.js';
import { getUsers, deleteUser } from '../../network/ApiAxios';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Toast from 'reactstrap/lib/Toast';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const Users = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getUsers().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setData(data.users);
      } else {
      }
    });
  }, []);
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0"></CardHeader>
              <MaterialTable
                icons={tableIcons}
                columns={[
                  {
                    title: 'Name',
                    render: (client) => {
                      return `${client.firstName} ${client.lastName}`;
                    },
                  },
                  { title: 'Email', field: 'email' },
                  { title: 'Phone', field: 'phone' },
                  { title: 'Balance', field: 'credit' },
                  { title: 'Company', field: 'company.name' },
                  { title: 'Team', field: 'team.name' },
                ]}
                data={data}
                actions={[
                  (rowData) => ({
                    icon: tableIcons.Delete,
                    tooltip: 'Delete User',
                    onClick: (event, rowData) =>
                      deleteUser(rowData._id).then((data) => {
                        if (data.user) {
                          Toast();
                        }
                      }),
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                  exportAllData: true,
                  exportButton: true,
                }}
              />
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
    </>
  );
};

export default Users;
