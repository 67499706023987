import React, { useEffect, useState, forwardRef } from 'react';

// reactstrap components
import { Modal, Button } from 'antd';
import {
  Card,
  CardHeader,
  Container,
  Row,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap';
// core components
import classnames from 'classnames';

import CashoutTable from './CashoutTable';
import Header from '../../../components/Headers/Header.js';
import { updateCashoutStatus, getCashouts } from '../../../network/ApiAxios';

export default function User() {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('PENDING');

  useEffect(() => {
    getData();
  }, [status]);

  const getData = () => {
    const cashoutType = 'FAWRY';
    getCashouts(status, cashoutType).then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setData(data.cashouts);
      } else {
      }
    });
  };
  const approveTransaction = (cashouts) => {
    updateCashoutStatus(cashouts).then(() => getData());
  };
  const declineTransaction = (cashouts) => {
    updateCashoutStatus(cashouts).then(() => getData());
  };

  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="nav-wrapper">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="tabs-icons-text"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        aria-selected={status === 'PENDING'}
                        className={classnames('mb-sm-3 mb-md-0', {
                          active: status === 'PENDING',
                        })}
                        onClick={(e) => setStatus('PENDING')}
                        href="#pablo"
                        role="tab"
                      >
                        <i className="ni ni-cloud-upload-96 mr-2" />
                        PENDING
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-selected={status === 'PAID'}
                        className={classnames('mb-sm-3 mb-md-0', {
                          active: status === 'PAID',
                        })}
                        onClick={(e) => setStatus('PAID')}
                        href="#pablo"
                        role="tab"
                      >
                        <i className="ni ni-cloud-upload-96 mr-2" />
                        PAID
                      </NavLink>
                    </NavItem>{' '}
                    <NavItem>
                      <NavLink
                        aria-selected={status === 'REJECTED'}
                        className={classnames('mb-sm-3 mb-md-0', {
                          active: status === 'REJECTED',
                        })}
                        onClick={(e) => setStatus('REJECTED')}
                        href="#pablo"
                        role="tab"
                      >
                        <i className="ni ni-cloud-upload-96 mr-2" />
                        REJECTED
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-selected={status === 'CANCELLED'}
                        className={classnames('mb-sm-3 mb-md-0', {
                          active: status === 'CANCELLED',
                        })}
                        onClick={(e) => setStatus('CANCELLED')}
                        href="#pablo"
                        role="tab"
                      >
                        <i className="ni ni-cloud-upload-96 mr-2" />
                        CANCELLED
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </CardHeader>
              <CashoutTable
                declineTransaction={declineTransaction}
                approveTransaction={approveTransaction}
                status={status}
                data={data}
                setData={setData}
                getData={getData}
                type="fawry"
              />
            </Card>
          </div>
        </Row>
        {/* Table */}
      </Container>
    </>
  );
}
