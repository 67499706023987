import React, { useEffect, useState, forwardRef } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// reactstrap components
import {
  Table,
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  Row,
  Col,
  hr,
  Control,
  Label,
} from 'reactstrap';
// core components

import MaterialTable from 'material-table';
import Header from '../../../components/Headers/Header.js';
import {
  getUsers,
  deleteCompany,
  getCompanies,
  getCountries,
  addCompany,
  addBalance,
} from '../../../network/ApiAxios';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
//import Toast from 'reactstrap/lib/Toast';
import Toast from 'react-bootstrap/Toast';
import { BorderAllRounded } from '@material-ui/icons';
import WalletHistory from './walletHistory.js';
import AddWallet from './AddToWallet.js';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const ToastSwal = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
const Companies = () => {
  const [data, setData] = useState([]);
  const [addToWalletModalVisibilty, setAddToWalletModalVisibilty] =
    useState(false);
  const [walletAmount, setWalletAmount] = useState({
    money: 0,
    transaction: 'BANK TRANSFARE',
  });
  const [countries, setCountries] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  const [company, setCompany] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    team: '',
    budget: '',
    phone: '',
    country: '',
    city: '',
    postCode: '',
    legalName: '',
    website: '',
    address: '',
    companyPhone: '',
  });
  const [addModalVisibilty, setAddModalVisibilty] = useState(false);
  const [walletModalVisibilty, setWalletModalVisibilty] = useState(false);

  useEffect(() => {
    getCountries().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setCountries(data.country);
        //   setCompany({ ...company, country: data.country[0]._id });
      } else {
      }
    });
  }, [setAddModalVisibilty]);

  useEffect(() => {
    getCompanies().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        setData(data.result);
      } else {
      }
    });
  }, []);
  const [showA, setShowA] = useState(false);
  const [responseContent, setResponse] = useState('');

  const submitBalance = () => {
    addBalance({ ...walletAmount, selectedCompanyId }).then((response) => {
      setAddToWalletModalVisibilty(false);
      setWalletAmount({
        money: 0,
        transaction: 'BANK TRANSFARE',
      });
      getCompanies().then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setData(data.result);
        } else {
        }
      });
    });
  };

  const toggleShowA = () => setShowA(!showA);
  const submitDisabled =
    company.firstName &&
    company.lastName &&
    company.email &&
    company.password &&
    company.role &&
    company.team &&
    company.budget &&
    company.phone &&
    company.country &&
    company.city &&
    company.postCode &&
    company.legalName &&
    company.website &&
    company.address &&
    company.companyPhone;
  //const [submitDisabled, setSubmitDisabled] = useState("");
  const [field, setField] = useState([]);
  const fireToast = (rowData) => {
    console.log('looooo');
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCompany(rowData._id)
          .then((data) => {
            console.log(data);

            const { status } = data;
            if (status == 200) {
              console.log('sucess');
              Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
              getCompanies().then((response) => {
                const { data, status } = response;
                if (status == 200) {
                  setData(data.result);
                } else {
                }
              });
            } else {
              console.log('failed');

              Swal.fire(
                'Something went wrong!',
                "The company hasn't been deleted.",
                'failed'
              );
            }
          })
          .catch((err) => {
            console.log('ghhgghgh');
          });
      } else {
        console.log(rowData._id);
      }
    });
  };
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/*toast */}
        <Row>
          <Col md={6} className="mb-2">
            <Toast
              show={showA}
              onClose={toggleShowA}
              style={{
                backgroundColor: 'white',
                borderRadius: '9px',
                padding: '5px',
              }}
            >
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Hollydesk Admin</strong>
                <small> Just now.</small>
              </Toast.Header>
              <Toast.Body>{responseContent}</Toast.Body>
            </Toast>
          </Col>
        </Row>
        {/*end of toast*/}

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0"></CardHeader>

              <MaterialTable
                icons={tableIcons}
                columns={[
                  { title: 'Name', field: 'name' },
                  { title: 'Phone', field: 'phone' },
                  { title: 'Country', field: 'country.country' },
                  {
                    title: 'Balance',
                    field: 'credit.$numberDecimal',
                    type: 'numeric',
                  },
                  { title: 'Created At', field: 'createdAt' },
                ]}
                data={data}
                actions={[
                  {
                    icon: tableIcons.Add,
                    tooltip: 'new company',
                    isFreeAction: true,
                    onClick: () => {
                      setAddModalVisibilty(true);
                      setCompany({ ...company, country: countries[0]._id });
                    },
                  },
                  {
                    icon: tableIcons.Delete,
                    tooltip: 'Delete Company',
                    onClick: (event, rowData) => {
                      fireToast(rowData);
                      /* deleteUser(rowData._id).then((data) => {
                        if (data.user) {
                          Toast();
                        }
                      })*/
                    },
                  },

                  {
                    icon: tableIcons.DetailPanel,
                    tooltip: 'wallet log',
                    onClick: (event, rowData) => {
                      setSelectedCompany(rowData.wallets);
                      setWalletModalVisibilty(true);

                      /* deleteUser(rowData._id).then((data) => {
                        if (data.user) {
                          Toast();
                        }
                      })*/
                    },
                  },
                  {
                    icon: tableIcons.Add,
                    tooltip: 'Add Ballance',
                    onClick: (event, rowData) => {
                      setSelectedCompanyId(rowData._id);
                      setAddToWalletModalVisibilty(true);

                      /* deleteUser(rowData._id).then((data) => {
                        if (data.user) {
                          Toast();
                        }
                      })*/
                    },
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  exportAllData: true,
                  exportButton: true,
                }}
              />
            </Card>
          </div>
        </Row>
        {/* Dark table */}
      </Container>
      <WalletHistory
        setWalletModalVisibilty={setWalletModalVisibilty}
        walletModalVisibilty={walletModalVisibilty}
        walletLogs={selectedCompany}
      />
      <AddWallet
        addToWalletModalVisibilty={addToWalletModalVisibilty}
        setAddToWalletModalVisibilty={setAddToWalletModalVisibilty}
        walletAmount={walletAmount}
        setWalletAmount={setWalletAmount}
        submitBalance={submitBalance}
      />
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={addModalVisibilty}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent ">
              <div className="">
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() => setAddModalVisibilty(!addModalVisibilty)}
                >
                  <span>×</span>
                </button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" autoComplete="off">
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="First Name"
                          type="text"
                          value={company.firstName}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({
                              ...company,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Last Name"
                          type="text"
                          value={company.lastName}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({
                              ...company,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* email and password */}
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Email"
                          type="text"
                          value={company.email}
                          autoComplete="off"
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, email: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Password"
                          type="password"
                          value={company.password}
                          autoComplete="new-password"
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({
                              ...company,
                              password: e.target.value,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* role and team */}
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Team Size"
                          type="text"
                          value={company.team}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, team: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Role"
                          type="text"
                          value={company.role}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, role: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* budget and phone */}
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Budget"
                          type="text"
                          value={company.budget}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, budget: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Phone Number"
                          type="text"
                          value={company.phone}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, phone: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* company legal name */}
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Company Legal Name"
                          type="text"
                          value={company.legalName}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({
                              ...company,
                              legalName: e.target.value,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* city and postal code */}
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="City"
                          type="text"
                          value={company.city}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, city: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Postal Code"
                          type="text"
                          value={company.postCode}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({
                              ...company,
                              postCode: e.target.value,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* website and postal address */}
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Address"
                          type="text"
                          value={company.address}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, address: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Website"
                          type="text"
                          value={company.website}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({ ...company, website: e.target.value });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {/* country and phone number address */}
                <Row>
                  <Col>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Company Phone Number"
                          type="text"
                          value={company.companyPhone}
                          onChange={(e) => {
                            e.preventDefault();
                            console.log(e.target.value);
                            setCompany({
                              ...company,
                              companyPhone: e.target.value,
                            });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Duration (Months)"
                          type="select"
                          value={company.country}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setCompany({ ...company, country: e.target.value });
                          }}
                        >
                          {countries.map((c) => (
                            <option value={c._id}>{c.country}</option>
                          ))}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={!submitDisabled}
                    onClick={() => {
                      addCompany(company).then((response) => {
                        console.log(response.data);
                        const { data, status } = response;
                        if (status == 200) {
                          setResponse(data.result);
                        } else {
                        }
                      });
                      toggleShowA();
                      setAddModalVisibilty(false);
                      setCompany({
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        role: '',
                        team: '',
                        budget: '',
                        phone: '',
                        country: '',
                        city: '',
                        postCode: '',
                        legalName: '',
                        website: '',
                        address: '',
                        companyPhone: '',
                      });

                      getCompanies().then((response) => {
                        const { data, status } = response;
                        if (status == 200) {
                          setData(data.result);
                        } else {
                        }
                      });
                    }}
                  >
                    Add Company{' '}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      <template id="my-template">
        <swal-title>Save changes to "Untitled 1" before closing?</swal-title>
        <swal-icon type="warning" color="red"></swal-icon>
        <swal-button type="confirm">Save As</swal-button>
        <swal-button type="cancel">Cancel</swal-button>
        <swal-button type="deny">Close without Saving</swal-button>
        <swal-param name="allowEscapeKey" value="false" />
        <swal-param name="customClass" value='{ "popup": "my-popup" }' />
      </template>
    </>
  );
};

export default Companies;
